import request from '@/plugins/axios'
// 登录
export const sendLoginData = (code: string): any =>
  request({
    url: `/v1/login/${code}`,
    method: 'post',
  })

// 获取验证码
export const obtainMessageCode = (phone: string): any =>
  request({
    url: `/v2/smscode/${phone}`,
    method: 'post',
  })

// 绑定手机号
export const bindPhoneId = (data: { phone: string; code: string }): any =>
  request({
    url: `/v2/bindphone`,
    method: 'post',
    params: data,
  })

// 微信分享获取初始化信息
export const getWxSDKInitData = (data: { url: string }): any =>
  request({
    url: '/v1/share/sign',
    method: 'post',
    params: data,
  })
